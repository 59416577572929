import {useState, useEffect, useRef, useContext, useLayoutEffect} from 'react';
import {useMutation} from 'react-query';
import API from 'api';
import {Button} from 'utils';
import {STEPS} from './LivenessContent';
import AppContext from 'components/App/AppContext';
import {ProgressBar} from 'utils/ProgressBar/ProgressBar';
import {livenessAdvancedVerification} from 'api/routes/smart_kyc';
import {appendPlusToNumber, getError, getFormData} from 'utils/helpers';
import {useCamera} from 'hooks';

const LivenessAdvancedCheckContent = ({result, handleStep}) => {
    const videoRef = useRef({});
    const {params} = useContext(AppContext);
    const {clientid, appname, apikey} = params || {};

    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState(3);
    const [recording, setRecording] = useState(false);
    const [mediaRecorded, setMediaRecorded] = useState(null);
    const [errorMsg, setErrorMsg] = useState();
    const [currentBlobs, setCurrentBlobs] = useState([]);
    const [startFile, setStartFile] = useState('');
    const [progress, setProgress] = useState(0);
    const loadingDuration = 4000;

    const {stream, startStream, stopStream} = useCamera({
        constraints: {
            video: {
                facingMode: 'user',
            },
        },
        onSuccess: stream => {
            setErrorMsg();
            setLoading(false);
            setTimeout(() => setCountdown(prev => prev - 1), 1000);

            if ('srcObject' in videoRef.current) {
                videoRef.current.srcObject = stream;
            } else {
                videoRef.current.src = URL.createObjectURL(stream);
            }

            videoRef.current.setAttribute('autoplay', '');
            videoRef.current.setAttribute('muted', '');
            videoRef.current.setAttribute('playsinline', '');
            videoRef.current.play();
        },
        onError: () => setErrorMsg('Camera not found'),
    });

    const startRecording = () => {
        setRecording(true);
        mediaRecorded?.start(1000);

        setTimeout(() => {
            setTimeout(() => {
                stopStream();
                setRecording(false);
            }, 1000);
        }, 4000);
    };

    useEffect(() => {
        let [timeout, progressTimeout] = ['', ''];
        if (countdown > 0 && countdown !== 0 && loading === false) {
            progressTimeout = setTimeout(() => setCountdown(prev => prev - 1), 1000);
        }
        if (progress < 100 && recording)
            progressTimeout = setTimeout(() => setProgress(progress + 1), loadingDuration / 100);
        return () => {
            clearTimeout(timeout);
            clearTimeout(progressTimeout);
        };
        // eslint-disable-next-line
    }, [countdown, progress, recording]);

    useLayoutEffect(() => {
        startStream();
        return () => stopStream();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const options = {videoBitsPerSecond: 2009000};
        let mediaRecorder;
        if (stream) {
            if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,vorbis')) {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                    mimeType: 'video/webm; codecs=vp8,vorbis',
                });
            } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus')) {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                    mimeType: 'video/webm;codecs=vp8,opus',
                });
            } else if (MediaRecorder.isTypeSupported('video/mp4')) {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                    mimeType: 'video/mp4',
                });
            } else {
                mediaRecorder = new MediaRecorder(stream, {
                    ...options,
                });
            }
            setMediaRecorded(mediaRecorder);
        }
    }, [stream]);

    useEffect(() => {
        if (mediaRecorded && mediaRecorded?.addEventListener) {
            setCurrentBlobs([]);
            setStartFile(false);
            mediaRecorded.addEventListener('dataavailable', e => {
                setCurrentBlobs(prev => [...prev, e.data]);
            });
            mediaRecorded.addEventListener('stop', async () => setStartFile(true));
        }
    }, [mediaRecorded]);

    useEffect(() => {
        if (startFile && currentBlobs) {
            const blob = new Blob(currentBlobs, {type: 'video/mp4'});
            const file = new File([blob], 'liveness.mp4', {
                type: 'video/mp4',
            });
            if (file) handleVerification(file);
            else {
                setErrorMsg('Liveness Verification Failed!');
            }
        }
        // eslint-disable-next-line
    }, [currentBlobs, startFile]);

    const {mutate} = useMutation(
        async payload => {
            setErrorMsg();
            const response = await API.post(livenessAdvancedVerification, payload, {
                headers: {
                    clientid,
                    appname,
                    apikey,
                },
            });
            return response;
        },
        {
            onSuccess: ({data, status}, formData) => {
                if (status === 200 || status === 201) {
                    handleStep({
                        step: STEPS.RESULT,
                        data: {file: formData.get('video'), ...(data?.data || {})},
                    });
                } else setErrorMsg(data?.message);
            },
            onError: error => setErrorMsg(getError(error)),
        }
    );

    const handleVerification = file => {
        setStep(2);
        stopStream();
        setErrorMsg();

        const formData = getFormData({
            video: file,
            phone_number: appendPlusToNumber(result.phone),
            image_url: result.image_url,
        });

        mutate(formData);
    };

    const reset = () => {
        stopStream();
        setErrorMsg();
        setCountdown(3);
        setLoading(true);
        setRecording(false);
        setMediaRecorded(null);
        setStep(1);
        setProgress(0);
        startStream();
    };

    const renderByStep = () => {
        if (step === 1)
            return (
                <ProgressBar progress={progress}>
                    {loading ? (
                        <div className="video-frame-loading">
                            <div className="h-100 flex__center">
                                <h4>Loading... Please Wait</h4>
                            </div>
                        </div>
                    ) : (
                        <video muted ref={videoRef} id="video" autoPlay="" playsInline="">
                            Video stream not available.
                        </video>
                    )}
                </ProgressBar>
            );

        if (step === 2) {
            return (
                <div className="verification-status">
                    <div className="text-center">
                        {errorMsg ? (
                            <>
                                <h4 className="py-4">{errorMsg}</h4>
                                <div className="flex__between">
                                    <Button
                                        name="Back"
                                        cls="btn--sm btn--secondary--bordered me-4 px-5"
                                        handleClick={() => handleStep({step: STEPS.PHONE})}
                                    />
                                    <Button
                                        name="Retry"
                                        cls="btn--sm btn--primary--bordered me-4 px-5"
                                        handleClick={reset}
                                    />
                                </div>
                            </>
                        ) : (
                            <h4 className="heading3">Verification in Progress. Please Wait...</h4>
                        )}
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <div className="video-frame py-5 mt-4">
                <div className="frame-edge" />
                <div className="frame-edge right" />
                <div className="frame-edge bottom-left" />
                <div className="frame-edge bottom-right" />
                <div className="flex__center">{renderByStep()}</div>
            </div>

            {step === 1 && (
                <Button
                    name={recording ? 'Liveness check in progress' : 'Start Liveness Check'}
                    cls="btn--sm btn--primary--bordered ms-5 px-5"
                    disabled={countdown !== 0 || progress > 1}
                    handleClick={startRecording}
                />
            )}
        </>
    );
};

export default LivenessAdvancedCheckContent;
